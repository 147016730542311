import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";


/* Hero */
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Heading = tw(HeadingBase)`text-center text-primary-500 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-300 max-w-lg mx-auto`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <StyledHeader links = { [
        <NavLinks key={1}>
          <NavLink href="/about">
            About
          </NavLink>
          <NavLink href="/thank-you">
            Thank-You
          </NavLink>
          <NavLink href="/steamservers">
            Ark Servers
          </NavLink>
        </NavLinks>
      ]} />
      <br></br>
      <br></br>
      <br></br>
      <Heading>
        Website Template
      </Heading>
      <Description>
        This Website is based on the Template "Treact" by Owais Khan. Check him out!
      </Description>

    </AnimationRevealPage>
  );
};
