import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/MainHero.js";
import Socials from "components/features/IanSocials.js";
import Footer from "components/footers/MainFooter";
import styled from "styled-components";
import {PrimaryLink as PrimaryLinkBase, NavLink, NavLinks} from "../components/headers/light";

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
export default () => (
    <AnimationRevealPage>
        <Hero
            navLinks = { [
                <NavLinks key={1}>
                    <NavLink href="/about">
                        About
                    </NavLink>
                    <NavLink href="/thank-you">
                        Thank-You
                    </NavLink>
                    <NavLink href="/steamservers">
                        Ark Servers
                    </NavLink>
                </NavLinks>
            ]}
        />
        <Socials />
        <Footer />
    </AnimationRevealPage>
);
