import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import FacebookIcon from "../../images/Facebook.png";
import TwitterIcon from "../../images/twitter-icon.png";
import YoutubeIcon from "../../images/youtube.png";
import DiscordIcon from "../../images/discord.png";
import TwitchIcon from "../../images/twitch.png";
import SteamIcon from "../../images/steam.png";

const Container = tw.div`relative`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full text-gray-300`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
  
  
`;


export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: TwitterIcon,
      primaryButtonUrl: "https://twitter.com/IanDresarie",
      primaryButtonText: "Twitter",
      description: "Twitter is definitely my primary home. Ping me here and you can expect an answer fast. I hope it doesn't die. :/"
    },
    { imageSrc: DiscordIcon,
      primaryButtonUrl: "https://discordapp.com/users/178233738109517824/",
      primaryButtonText: "Discord",
      description: "Discord is probably a close second. I have it on auto-start and usually check in regularly."},
    { imageSrc: FacebookIcon,
      primaryButtonUrl: "",
      primaryButtonText: "Facebook",
      description: "Facebook is dead. I'm not giving you my old profile! :D"},
    { imageSrc: YoutubeIcon,
      primaryButtonUrl: "https://youtube.com/IanDresarie",
      primaryButtonText: "YouTube",
      description: "Not that I have had much time for this in recent years. But there's still some old stuff worth watching I guess."},
    { imageSrc: SteamIcon,
      primaryButtonUrl: "https://steamcommunity.com/id/iandresarie",
      primaryButtonText: "Steam",
      description: "No idea if you can contact me, but you can definitely watch me spiral down my game addiction!"},
    { imageSrc: TwitchIcon,
      primaryButtonUrl: "https://www.twitch.tv/iandresarie",
      primaryButtonText: "Twitch",
      description: "Not much recent activity here either. But who knows?"}
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>My <span tw="text-primary-500">Socials</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <PrimaryButton buttonRounded="true" as="a" href={card.primaryButtonUrl}>
                  {card.primaryButtonText}
                </PrimaryButton>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
