import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import ServerCard from "components/features/ServerCard.js";
import axios from "axios";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";

/**
 * Url, auf der der Checker-Server läuft.
 * @type {string}
 */
const statusCheckerUrl = "https://arkisland.starleague.me/";

/**
 * Array mit Infos zu den Servern im Format [Servername,Port]
 * @type {(string|number)[][]}
 */
const serverList = [['The Island',27015],["Scortched Earth",27016],["The Center - Legacy",27018],["The Island - Hidden Lake - Legacy",27017]]
/**
 * Url auf dem die Server laufen
 * @type {string}
 */
const serverUrl = 'starleague.me';

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export default () => {
    const [post, setPost] = React.useState(null);

    let fullUrl = statusCheckerUrl;
    fullUrl += "?serverUrl="+serverUrl;
    for (let t = 0; t < serverList.length; t++) {
        fullUrl += "&server"+t+"=";
        fullUrl += serverList[t][1];
    }
    console.log(fullUrl);

    React.useEffect(() => {
        console.log("requesting")
        axios.get(fullUrl).then((response) => {
            console.log(response.data)
            setPost(response.data);
        });
    }, []);

  return (
    <AnimationRevealPage>
        <StyledHeader links = { [
            <NavLinks key={1}>
                <NavLink href="/about">
                    About
                </NavLink>
                <NavLink href="/thank-you">
                    Thank-You
                </NavLink>
                <NavLink href="/steamservers">
                    Ark Servers
                </NavLink>
            </NavLinks>
        ]} />
        {/*Server as a foreach*/}
        {serverList.map((server, forEachIndex) => {
            if(!post)
                 return (<ServerCard
                    subheading={<Subheading>{server[0]}</Subheading>}
                    heading="Waiting on response from Status-Checker...If this doesn't go away within 10 seconds, pls message Ian urgently!"
                    description=""
                    buttonRounded={false}
                    primaryButtonText="Message Ian"
                    primaryButtonUrl="https://discordapp.com/users/178233738109517824/"
                    imageSrc="https://thumbs.dreamstime.com/b/waiting-icon-trendy-design-style-waiting-icon-isolated-white-background-waiting-vector-icon-simple-modern-flat-symbol-135740731.jpg"
                />);
                else
                if (post[forEachIndex].serverName == "Connection to server timed out." || post[forEachIndex].serverName == null)
                    return (<ServerCard
                        subheading={<Subheading>{server[0]}</Subheading>}
                        heading={"Ark Server is offline"}
                        description={post[forEachIndex].serverName}
                        description2={"Please Message Ian about this, if you think it should be running!"}
                        buttonRounded={false}
                        primaryButtonText="Message Ian"
                        primaryButtonUrl="https://discordapp.com/users/178233738109517824/"
                        imageSrc="https://www.ub.tum.de/files/icon_offline.png"
                    />);
                else {
                    var buttonUrl = "";
                    switch (post[forEachIndex].map) {
                        case 'TheIsland':
                            buttonUrl = "https://ark.gamepedia.com/media/ark.gamepedia.com/thumb/0/04/The_Island_Topographic_Map.jpg/600px-The_Island_Topographic_Map.jpg";
                            break;
                        default:
                            buttonUrl = "https://image.api.playstation.com/cdn/EP0688/CUSA06782_00/4Fn75AWWgOLZiFdxIA6HvSV5wC9c3WGp.png"
                            break;
                    }
                    return (<ServerCard
                        subheading={<Subheading>{server[0]}</Subheading>}
                        heading={"Ark Server: " + post[forEachIndex].serverName + " is online!"}
                        description={"Game being played = " + post[forEachIndex].gameDescription}
                        description2={"on Map = " + post[forEachIndex].map}
                        description3={post[forEachIndex].numberOfPlayers + " / " + post[forEachIndex].maxNumberOfPlayers + " player"}
                        buttonRounded={false}
                        primaryButtonText="Join the Server!"
                        primaryButtonUrl={post[forEachIndex].connectUrl + "123/"}
                        imageSrc={buttonUrl}
                    />);
                }
        })}

        }


    </AnimationRevealPage>
  );
};